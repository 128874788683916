<template>
    <div class="flex items-center justify-center bg-gray-100">
        <div class="bg-white p-6 rounded-lg max-w-md text-center">
            <h4 class="text-xxl font-semibold text-gray-800 mb-4">⚠️ {{ $t("Warning") }}!</h4>
            <p class="text-gray-600 text-md mb-4" v-html="statusWarningMessage"></p>
            <vs-button @click="changeAutomationStatus(automationId)">
                Confirmar
            </vs-button>
        </div>
    </div>
</template>

<script>
export default {
    name: "WarningPopup",
    props: {
        statusWarningMessage: {
            type: String,
            required: true
        },
        currentStatus: {
            type: Number,
            required: true
        },
        automationId: {
            type: String,
            required: true,
        }
    },
    methods: {
        async changeAutomationStatus(id) {
            this.$vs.loading();

            const newStatus = this.currentStatus === 1 ? 2 : 1;

            const params = {
                acc: this.$store.state.acc.current_acc.id,
                status: newStatus,
            };

            try {
                await this.$http.patch(`/p/automation/update/${id}`, params, {
                    headers: {
                        Authorization: "Bearer " + this.$store.state.auth.accessToken,
                        "Content-Type": "Application/json",
                    }
                });
                this.$vs.loading.close();
                this.showUpdateSuccess();
                this.$emit("refreshTable")
                this.$emit("closeWarningPopup");

            } catch (error) {
                this.$vs.notify({
                    time: 2500,
                    title: this.$t("Error"),
                    text: this.$t("UnexpectedError"),
                    iconPack: "feather",
                    icon: "icon-x",
                    color: "danger",
                    position: "top-right",
                });
            } finally {
                this.$vs.loading.close();
            }

        },
        showUpdateSuccess() {
            this.$vs.notify({
                color: "success",
                title: this.$t("Confirmation"),
                text: this.$t("AutomationUpdatedSuccessfully"),
                position: "top-right",
                time: 4000,
            });
        },

    }
}
</script>