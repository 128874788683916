<template>
  <vs-card>
    <div slot="header" class="flex items-center justify-between mx-4 mt-4">
      <h3>{{ $t('Automation') }}</h3>
      <div class="flex items-center justify-between gap-x-4">
        <vs-button icon-pack="feather" icon="icon-file-text" color="success" @click="popupActive = true">{{
          $t("AddAutomation") }}</vs-button>
      </div>
    </div>
    <vs-row>
      <vs-col vs-w="12">
        <vs-table hoverFlat stripe :data="automationData" :noDataText="$t('EmptyTable')" :notSpacer="true"
          class="userTable">
          <template slot="thead">
            <vs-th>Título</vs-th>
            <vs-th>Gatilho</vs-th>
            <vs-th>Ação</vs-th>
            <vs-th>Tempo</vs-th>
            <vs-th>Intenções</vs-th>
            <vs-th>Entidades chave</vs-th>
            <vs-th>Conversas</vs-th>
            <vs-th>Criado por</vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr v-for="(tr, indextr) in data" :key="indextr" :class="tr.actions.status === 2 ? 'userDisabled' : ''">
              <vs-td :data="tr.title"> {{ tr.title }} </vs-td>
              <vs-td :data="tr.trigger" :title="tr.trigger"> {{ tr.trigger }} </vs-td>
              <vs-td :data="tr.action"> {{ tr.action === 'sendMessage' ? 'Enviar mensagem' : 'Enviar template' }} </vs-td>
              <vs-td :data="tr.time"> {{ tr.timeHour }}h:{{ tr.timeMinute }}m </vs-td>
              <vs-td :data="tr.intentions" class="text-truncate" :title="tr.intentions"> {{ formatArray(tr.intentions) }} </vs-td>
              <vs-td :data="tr.entities" class="text-truncate" :title="tr.entities"> {{ formatArray(tr.entities) }} </vs-td>
              <vs-td :data="tr.stages"> {{ formatStages(tr.stages) }} </vs-td>
              <vs-td :data="tr.createdBy"> {{ tr.createdBy }} </vs-td>
              <vs-td :data="tr.actions">
                <div class="flex items-center">
                  <vs-button icon-pack="feather" icon="icon-search" type="flat" color="dark" @click="openAutomationLogsPopup(tr.actions._id)"/>
                  <vs-dropdown v-if="$acl.check('admin')" vs-custom-content vs-trigger-click
                  class="dark-btn rounded-md cursor-pointer">
                    <vs-button icon-pack="feather" icon="icon-more-vertical" type="flat" color="dark" />
                    <vs-dropdown-menu>
                      <vs-dropdown-item @click="warningPopupActive = true">
                        {{ tr.actions.status === 2 ? "Ativar" : "Desativar" }}
                      </vs-dropdown-item>
                      <vs-dropdown-item @click="openViewAutomationPopup(tr.actions._id)">
                        {{ $t("View") }}
                      </vs-dropdown-item>
                      <vs-dropdown-item @click="confirmDeleteRecord(tr.actions._id)">
                        {{ $t("Delete") }}
                      </vs-dropdown-item>
                    </vs-dropdown-menu>
                  </vs-dropdown>
                </div>
                <vs-popup :title="$t('Automation')" v-if="warningPopupActive" :active.sync="warningPopupActive">
                  <WarningPopup
                  :statusWarningMessage="tr.actions.status === 2 ? $t('ActivateAutomation') : $t('DeactivateAutomation')"
                  :currentStatus="tr.actions.status"
                  :automationId="tr.actions._id"
                  @refreshTable="fetchAutomationData()"
                  @closeWarningPopup="closeWarningPopup()"
                  />
                </vs-popup>
              </vs-td>
            </vs-tr>
            <vs-popup :title="$t('Automation')" v-if="viewAutomationPopupActive" :active.sync="viewAutomationPopupActive">
              <ViewAutomationPopup :automationId="currentAutomationId" />
            </vs-popup>
          </template>
        </vs-table>
      </vs-col>
      <vs-popup :title="$t('Automation')" v-if="popupActive" :active.sync="popupActive">
        <createAutomationPopup 
        :intentions="intentions" 
        :entities="entities"
        @closeCreatePopup="closeCreatePopup()"
        @refreshTable="fetchAutomationData()" 
        />
      </vs-popup>
      <vs-popup :title="$t('AutomationReport')" :active.sync="popupLogsActive" fullscreen>
       <vs-row>
        <vs-col class="mt-5">
          <vs-table :max-items="10" :data="automationQueueData" stripe :hoverFlat="true">
            <template v-slot:thead>
              <vs-th></vs-th>
              <vs-th>{{ $t("Name") }}</vs-th>
              <vs-th>{{ $t("Phone") }}</vs-th>
              <vs-th>{{ $t("Date") }}</vs-th>
              <vs-th>{{ $t("Status") }}</vs-th>
              <vs-th>{{ $t("ErrorMessage") }}</vs-th>
            </template>
            <template v-slot:default="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="indextr + 1">
                  {{ indextr + 1 }}
                </vs-td>
                <vs-td>
                  {{ tr.contactData.name }}
                </vs-td>
                <vs-td>
                  {{ formatPhone(tr.contactData.phone) }}
                </vs-td>
                <vs-td>
                  {{ new Date(tr.automationConclusionDate).toLocaleDateString() }}
                </vs-td>
                <vs-td :data="tr.status">
                  <vs-chip color="warning" v-if="tr.status === 1">
                    {{ $t("Waiting") }}
                  </vs-chip>
                  <vs-chip color="success" v-if="tr.status === 5">
                    {{ $t("Success") }}
                  </vs-chip>
                  <vs-chip color="danger" v-else-if="tr.status === 6">
                    {{ $t("Error") }}
                  </vs-chip>
                </vs-td>
                <vs-td>
                  {{ tr.statusResponse ? tr.statusResponse : '-' }}
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <!-- <vs-pagination class="mt-4" :total="campaignDetailsSending.pages" v-model="campaignDetailsPage" /> -->
        </vs-col>
      </vs-row> 
    </vs-popup>
    </vs-row>
  </vs-card>
</template>

<script>
import CreateAutomationPopup from "../../../components/createAutomationPopup.vue";
import ViewAutomationPopup from "../../../components/viewAutomationPopup.vue";
import WarningPopup from "../../../components/warningPopup.vue";
import { AsYouType, parsePhoneNumber } from "libphonenumber-js";

export default {
  components: {
    CreateAutomationPopup,
    WarningPopup,
    ViewAutomationPopup
  },
  data() {
    return {
      automationData: [],
      automationQueueData: [],
      intentions: [],
      entities: [],
      message: "",
      popupActive: false,
      warningPopupActive: false,
      viewAutomationPopupActive: false,
      popupLogsActive: false,
      currentAutomationId: null,
      stages: { 1: "Campanha", 2: "Aguardando Atendimento", 3: "Em Atendimento" },
    };
  },
  methods: {
    closeCreatePopup() {
      this.popupActive = false;
    },
    closeWarningPopup() {
      this.warningPopupActive = false;
    },
    openViewAutomationPopup(automationId) {
      this.currentAutomationId = automationId;
      this.viewAutomationPopupActive = true;
    },
    openAutomationLogsPopup(automationId) {
      this.fetchAutomationLogs(automationId);
      this.popupLogsActive = true;
    },
    formatArray(array) {
      return array.length ? array.join(', ') : '-';
    },
    formatStages(stageData) {
      if (stageData.length > 1) {
        return stageData.map(stage => this.stages[stage]).join(', ');
      }
      return this.stages[stageData];
    },
    formatPhone(phone) {
      if (phone === undefined || phone === "" || phone === null) return phone;

      if (phone.startsWith("55"))
        return (
          "+" +
          phone.slice(0, 2) +
          " (" +
          phone.slice(2, 4) +
          ") " +
          phone.slice(4, phone.length === 13 ? 9 : 8) +
          "-" +
          phone.slice(phone.length === 13 ? 9 : 8, phone.length)
        );

      const parsedNumber = parsePhoneNumber("+" + phone);

      return (
        "+" +
        parsedNumber.countryCallingCode +
        " " +
        new AsYouType(parsedNumber.country).input(parsedNumber.nationalNumber)
      );
    },
    // formatDate(dateString) {
    //   const date = new Date(dateString);
    //   const day = String(date.getDate()).padStart(2, '0');
    //   const month = String(date.getMonth() + 1).padStart(2, '0');
    //   const year = date.getFullYear();
    //   const hours = String(date.getHours()).padStart(2, '0');
    //   const minutes = String(date.getMinutes()).padStart(2, '0');
    //   const seconds = String(date.getSeconds()).padStart(2, '0');

    //   return `${day}/${month}/${year} às ${hours}:${minutes}:${seconds}`;
    // },
    async fetchAutomationData() {
      this.$vs.loading();

      const params = {
        acc: this.$store.state.acc.current_acc.id,
      };

      try {
        const response = await this.$http.get(`/p/automation/list`, { params });

        this.automationData = response.data.data.automations.map((automation) => ({
          title: automation.title,
          action: automation.automationSetup.action,
          message: automation.automationSetup.message,
          trigger: automation.trigger === 'inactivity' ? 'Sem resposta' : automation.trigger,
          // timeDay: automation.automationSetup.timeDay,
          timeHour: automation.automationSetup.timeHour,
          timeMinute: automation.automationSetup.timeMinute,
          intentions: automation.automationSetup.intentions,
          entities: automation.automationSetup.entities,
          stages: automation.automationSetup.stages,
          createdBy: automation.userInfo[0].name,
          actions: {
            _id: automation._id,
            status: automation.status,
          }
        }));
      } catch (error) {
        this.$vs.notify({
          time: 2500,
          title: this.$t("Error"),
          text: this.$t("UnexpectedError"),
          iconPack: "feather",
          icon: "icon-x",
          color: "danger",
          position: "top-right",
        });
      } finally {
        this.$vs.loading.close();
      }
    },
    async fetchAutomationLogs(automationId) {
      this.$vs.loading();
      const params = {
        acc: this.$store.state.acc.current_acc.id,
      };

      try {
        const response = await this.$http.get(`/p/automation/logs/${automationId}`, {
              params: params,
              headers: {
                  Authorization: "Bearer " + this.$store.state.auth.accessToken,
                  "Content-Type": "Application/json",
              }
          });
        this.automationQueueData = response.data.data;

      } catch (error) {
        this.$vs.notify({
          time: 2500,
          title: this.$t("Error"),
          text: this.$t("UnexpectedError"),
          iconPack: "feather",
          icon: "icon-x",
          color: "danger",
          position: "top-right",
        });
      } finally {
        this.$vs.loading.close();
      }
    },
    confirmDeleteRecord(id) {
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: this.$t("ConfirmRemoveUserAccess"),
        text: this.$t("ConfirmRemoveAutomation"),
        accept: () => this.deleteRecord(id),
        acceptText: this.$t("Delete"),
        cancelText: this.$t("Cancel"),
      });
    },
    async deleteRecord(id) {
      this.$vs.loading();

      const params = {
        acc: this.$store.state.acc.current_acc.id,
      };

      try {
        await this.$http.delete(`/p/automation/delete/${id}`, {
          data: params,
          headers: {
            Authorization: "Bearer " + this.$store.state.auth.accessToken,
            "Content-Type": "Application/json",
          }
        });
        this.$vs.loading.close();
        this.showDeleteSuccess();
        this.fetchAutomationData();

      } catch (error) {
        this.$vs.loading.close()
      }

    },
    showDeleteSuccess() {
      this.$vs.notify({
        color: "success",
        title: this.$t("Confirmation"),
        text: this.$t("AutomationDeletedSuccessfully"),
        position: "top-right",
        time: 4000,
      });
    },
  },
  mounted() {
    this.fetchAutomationData();
  },
};
</script>

<style>
.userTable .userDisabled td span {
  color: #d2d2d2;
}

.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px; 
}

</style>
