<template>
  <div>
    <vs-row>
      <vs-col vs-w="12">
        <vs-select width="100%" v-model="createAutomation.trigger" :label="$t('Trigger')" disabled>
          <vs-select-item v-for="(trigger, index) in triggers" :value="trigger.value" :text="trigger.label"
            :key="index" />
        </vs-select>
      </vs-col>
    </vs-row>
    <vs-row>
      <vs-col vs-w="12">
        <vs-input name="title" v-model="createAutomation.title" type="text" class="w-full mt-4" :label="$t('Title')" />
      </vs-col>
    </vs-row>
    <vs-col vs-w="12" class="mt-4">
      <div class="vs-component vs-con-input-label vs-input w-full vs-input-primary">
        <label class="vs-input--label mb-2">{{ $t("Chatbots") }}</label>
        <vs-select class="w-full" v-model="chat" autocomplete placeholder="Selecione">
          <vs-select-item v-for="(chat, chatIndex) in chatbotData" :value="chat" :text="chat.name" :key="chatIndex" />
        </vs-select>
      </div>
    </vs-col>
    <vs-col vs-w="12" class="mt-4" v-if="Object.keys(chat).length">
      <div class="vs-component vs-con-input-label vs-input w-full vs-input-primary">
        <label class="vs-input--label mb-2">{{ $t("Intention") }}</label>
        <v-select v-model="createAutomation.automationSetup.intentions" :options="chat.intentions" multiple />
      </div>
    </vs-col>
    <vs-col vs-w="12" class="mt-4" v-if="Object.keys(chat).length">
      <div class="vs-component vs-con-input-label vs-input w-full vs-input-primary">
        <label class="vs-input--label">{{ $t("KeyEntities") }}</label>
        <v-select v-model="createAutomation.automationSetup.entities" :options="chat.entities" multiple />
      </div>
    </vs-col>
    <vs-col vs-w="12" class="mt-4" v-if="Object.keys(chat).length">
      <div class="vs-component vs-con-input-label vs-input w-full vs-input-primary">
        <label class="vs-input--label">{{ $t("ConversationStage") }}</label>
        <v-select v-model="createAutomation.automationSetup.stages" :options="stages" multiple />
      </div>
    </vs-col>
    <vs-col vs-w="12" class="mt-4" v-if="Object.keys(chat).length">
      <vs-select width="100%" v-model="createAutomation.automationSetup.action" :label="$t('Action')">
        <vs-select-item v-for="(action, index) in actions" :value="action.value" :text="action.label" :key="index" />
      </vs-select>
    </vs-col>
    <vs-row>
      <vs-col vs-w="12" class="mt-4" v-if="Object.keys(chat).length">
        <label class="vs-input--label">Quando?</label>
        <div class="flex items-center">
          <label class="mr-4 mt-4 vs-input--label">Enviar após</label>
          <div>
            <!-- <vs-input-number :label="$t('Day')" v-model="createAutomation.automationSetup.timeDay" :min="0" :max="7" /> -->
            <vs-row class="flex space-x-4">
              <vs-col class="flex-1">
                <vs-input
                  type="number"
                  min="1"
                  placeholder="1"
                  v-model="createAutomation.automationSetup.timeMinute" 
                  :label="$t('Minutes')"
                />
              </vs-col>
              <vs-col class="flex-1">
                <vs-input
                  type="number"
                  min="0"
                  v-model="createAutomation.automationSetup.timeHour"
                  :label="$t('Hours').charAt(0).toUpperCase() + $t('Hours').slice(1)"
                />
              </vs-col>
            </vs-row>
          </div>
        </div>
      </vs-col>
    </vs-row>
    <vs-col vs-w="12" class="mt-4" v-if="createAutomation.automationSetup.action === 'sendTemplate'">
      <div class="vs-component vs-con-input-label vs-input w-full vs-input-primary">
        <label class="vs-input--label">{{ $t("Templates") }}</label>
        <v-select 
          v-model="createAutomation.automationSetup.templateID" 
          :options="templateData" 
          :reduce="template => template.id"
          label="title"
        />
      </div>
      <!-- <div class="mt-1 flex items-center" @click="open360Template()">
       <vs-icon icon-pack="feather" icon="icon-external-link" color="primary" />
        <span class="ml-1 text-primary">Visualizar templates</span>      
      </div> -->
    </vs-col>
    <vs-col vs-w="12" class="mt-4 w-full" v-if="createAutomation.automationSetup.action === 'sendTemplate'">
      <div class="text-primary background-color px-2 py-3 rounded-lg w-full">O envio de templates está sujeito a custos adicionais</div>
    </vs-col>
    <vs-col vs-w="12" class="mt-4"  v-if="Object.keys(chat).length && createAutomation.automationSetup.action !== 'sendTemplate'">
      <vs-textarea name="message" class="w-full my-3" v-model="createAutomation.automationSetup.message"
        style="cursor: not-allowed" rows="5" placeholder="Escreva uma mensagem..." />
    </vs-col>
    <vs-col vs-type="flex" vs-justify="flex-end" class="mt-6 mb-6 w-full">
      <vs-button class="w-full" icon-pack="feather" icon="icon-save" size="medium" @click=createNewAutomation() color="#28c66f">
        {{ $t("Save") }}
      </vs-button>
    </vs-col>
  </div>
</template>

<script>
import axios from "@/axios";
import vSelect from "vue-select";
export default {
  components: {
    vSelect,
  },
  name: "createAutomationPopup",
  data() {
    return {
      triggers: [
        { label: this.$t("Inactivity"), value: "inactivity" }
      ],
      actions: [
        { label: this.$t("SendMessage"), value: "sendMessage" },
        { label: this.$t("SendTemplate"), value: "sendTemplate" }
      ],
      stages: [
        // { label: this.$t("Campaign"), value: 1 },
        { label: this.$t("WaitingAttendance"), value: 2 },
        { label: this.$t("InAttendance"), value: 3 },
      ],
      chat: {},
      createAutomation: {
        acc: this.$store.state.acc.current_acc.id,
        user: this.$store.state.user._id,
        title: null,
        chat: null,
        trigger: "inactivity",
        automationSetup: {
          timeCalculated: 0,
          timeDay: 0,
          timeHour: 0,
          timeMinute: 1,
          entities: [],
          intentions: [],
          stages: [],
          action: "sendMessage",
          message: null,
          templateID: null,
        },
      },
      chatbotData: null,
      templateData: [],
    };
  },
  watch: {
    chat() {
      this.createAutomation.automationSetup.intentions = [];
      this.createAutomation.automationSetup.entities = [];
      const all = this.$t("All");
      if (Object.keys(this.chat).length) {
        if (!this.chat.intentions.includes(this.$t("All"))) {
          this.chat.intentions.push(all);
        }
        if (!this.chat.entities.includes(this.$t("All"))) {
          this.chat.entities.push(all);
        }
        if (this.createAutomation.automationSetup.action === "sendTemplate") {
          this.getTemplates();
        }
      }
    },
    "createAutomation.automationSetup.intentions": {
      immediate: true,
      handler() {
        if (this.createAutomation.automationSetup.intentions.length > 1 && this.createAutomation.automationSetup.intentions.includes(this.$t("All"))) {
          this.createAutomation.automationSetup.intentions = [this.$t("All")];
        }
      }
    },
    "createAutomation.automationSetup.entities": {
      immediate: true,
      handler() {
        if (this.createAutomation.automationSetup.entities.length > 1 && this.createAutomation.automationSetup.entities.includes(this.$t("All"))) {
          this.createAutomation.automationSetup.entities = [this.$t("All")];
        }
      }
    },
    "createAutomation.automationSetup.action": {
      immediate: true,
      handler() {
        if (this.createAutomation.automationSetup.action !== null && this.createAutomation.automationSetup.action === "sendTemplate" && this.templateData.length === 0) {
          this.getTemplates();
        }
      }
    },
  },
  computed: {},
  beforeMount() {
    this.getChats();
  },
  methods: {
    getChats() {
      this.$http
        .get("/p/chat/list", {
          params: { acc: this.$store.state.acc.current_acc.id },
        })
        .then((response) => {
          this.chatbotData = response.data.data;
        })
        .catch((error) => {
          if (typeof error.request !== "undefined")
            if (typeof error.request.response !== "undefined")
              error.message = JSON.parse(error.request.response).message;
            else error.message = this.$t("UnexpectedErrorLoadX", ["chatbots"]);
          this.$vs.notify({
            title: this.$t("UnexpectedError"),
            text: error.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "warning",
            position: "top-right",
            time: 4000,
          });
        });
    },
    async createNewAutomation() {
      try {
        const body = this.createAutomation;
        body.chat = this.chat._id;
        body.automationSetup.timeCalculated = (/* (this.createAutomation.automationSetup.timeDay * 24 * 60) + */ (this.createAutomation.automationSetup.timeHour * 60) + this.createAutomation.automationSetup.timeMinute) * 1000;
        // body.automationSetup.timeDay = parseInt(body.automationSetup.timeDay);
        body.automationSetup.timeHour = parseInt(body.automationSetup.timeHour);
        body.automationSetup.timeMinute = parseInt(body.automationSetup.timeMinute);

        await axios.post("/p/automation/create", body, {
          headers: {
            Authorization: "Bearer " + this.$store.state.auth.accessToken,
            "Content-Type": "Application/json",
          }
        });
        this.$vs.notify({
          time: 2500,
          title: this.$t("Confirmation"),
          text: this.$t("AutomationCreated"),
          iconPack: "feather",
          icon: "icon-check",
          color: "success",
          position: "top-right",
        });

        this.$emit("closeCreatePopup");
        this.$emit("refreshTable");
      } catch (e) {
        this.$vs.notify({
          time: 2500,
          title: this.$t("Error"),
          text: e,
          iconPack: "feather",
          icon: "icon-check",
          color: "danger",
          position: "top-right",
        });
      }
    },
    async getTemplates() {
      const accId = this.$store.state.acc.current_acc.id;
      const chatId = this.chat._id;

      this.$vs.notify({
              time: 4000,
              title: this.$t("Warning"),
              text: this.$t("TemplatesLoading"),
              iconPack: "feather",
              icon: "icon-x",
              color: "warning",
              position: "top-right",
          });
          
        this.$vs.loading();
      
      try {
          const response = await this.$http.post(`/p/chat/inbox/load360DialogTemplates/${accId}`, { 
            chat: chatId, 
          });
        
          response.data.data.map((template) => {
            this.templateData.push({ id: template._id, title: template.title });
            return;
          });

          this.$vs.loading.close();
      } catch (error) {

          this.$vs.notify({
              time: 4000,
              title: this.$t("Error"),
              text: this.$t("NoChatbotTemplate"),
              iconPack: "feather",
              icon: "icon-x",
              color: "danger",
              position: "top-right",
          });
          this.$vs.loading.close();
        }
      }
    }
  };
</script>
<style>
.vs-input-number span {
  width: 42.88px;
}

.background-color {
  background-color: #e5e1fe;
}
</style>
